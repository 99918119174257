import React from 'react';
import PrivateRoute from '../../components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "../../components/layout/PageError";
import Post from "../../pages/authorized/Post";
import Subscriber from "../../pages/authorized/Subscriber";
import AccessControl from "../../pages/authorized/AccessControl";
import License from "../../pages/authorized/License";

const Private = () => {
    return (
        <div>
            <Switch>
                <PrivateRoute exact path="/license" component={License} />
                <PrivateRoute exact path="/post" component={Post} />
                <PrivateRoute exact path="/subscriber" component={Subscriber} />
                <PrivateRoute exact path="/access_control" component={AccessControl} />
                <Route component={PageError} />
            </Switch>
        </div>
    )
};

export default Private;
