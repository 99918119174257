import React, {useEffect, useMemo, useRef, useState} from 'react';
import {GET_DATA_LIST, CREATE_LICENSE_URL} from '../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import "parsleyjs";
import $ from "jquery";
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import {updateLicense, deleteLicense} from "../../request/apps";
import axios from "axios";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const License = () => {

    document.title = "License";

    const token = localStorage.getItem('jwtToken');
    const quillRef = useRef();

    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [licenseId, setLicenseId] = useState(null);
    const [cover_image, set_cover_image] = useState(null);
    const [value, setValue] = useState("");

    useEffect(() => {
        $("#topic_selected").val("");
        $("#author").val("Within Here");
        var table = $('#license').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Fetching License list</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_DATA_LIST, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "License",
                        fieldname: "title",
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        let array = [];
                        res.data.map((data) => {array.push({raw: data})});
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.type;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.code.toUpperCase();
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.mode;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return `${data.size} Hectares`;
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_license"><i class="fa fa-pencil"></i> Edit</a><a href="#" class="nav-link delete_license"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        $('#license').on('click', '.delete_license', function() {
            let extract_td = table.row($(this).closest('tr')).data();

            const options = {
                position: "top-right",
                hideAfter: 5
            };

            let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

            let raw_message = {
                id: extract_td.raw._id
            }

            deleteLicense(raw_message).then((result) => {
                hide();
                const _options = {
                    position: "top-right"
                };

                if(result.error !== true) {
                    $('#license').DataTable().ajax.reload(null, false);
                    cogoToast.success(result.data, _options);
                }
            });

            return false;
        });

        $('#license').on('click', '.edit_license', function() {
            let extract_td = table.row($(this).closest('tr')).data();

            setEditForm(true);
            setLicenseId(extract_td.raw._id);
            $(".ql-editor")[0].innerHTML = atob(extract_td.raw.description);
            $("#type").val(extract_td.raw.type);
            $("#commodity").val(extract_td.raw.commodity);
            $("#mode").val(extract_td.raw.mode);
            $("#province").val(extract_td.raw.province);
            $("#size").val(extract_td.raw.size);
            $("#whatsapp").val(extract_td.raw.contact.whatsapp);
            $("#email").val(extract_td.raw.contact.email);
            $("#mobile_number").val(extract_td.raw.contact.mobile_number);

            $('#add_license').parsley();
            $("#modalAddLicense").modal("show");

            return false;
        });

    }, []);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'},
                        {'indent': '-1'}, {'indent': '+1'}],
                    ['clean']
                ],
                history: {
                    delay: 500,
                    maxStack: 100,
                    userOnly: true,
                },
            },
        }),
        []
    );

    const handleShowModal = () => {
        setEditForm(false);
        $('#modalAddLicense').modal({
            backdrop: 'static',
            keyboard: false
        });

        $('#email').val("info@geosetzambia.com");
        $('#add_license').parsley();
        $("#modalCreatePost").modal("show");
    };

    const handleLicense = async(e) => {
        e.preventDefault();

        const option = {
            position: "top-right",
            hideAfter: 3
        };

        setStateReady(true);

        let data = {
            type: $('#type').val(),
            commodity: $('#commodity').val(),
            description: btoa(value),
            size: $('#size').val(),
            mode: $('#mode').val(),
            province: $('#province').val(),
            whatsapp: $('#whatsapp').val(),
            email: $('#email').val(),
            mobile_number: $('#mobile_number').val()
        }

        if(editForm) {
            Object.assign(data, {id: licenseId});
            updateLicense(data).then((result) => {
                setStateReady(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalAddLicense", "add_license");
                    reloadTable();
                }
            });

            return false;
        }

        axios.post(CREATE_LICENSE_URL, data).then((response) => {
            setStateReady(false);
            cogoToast.success(response.data.payload, option);

            $("#modalAddLicense").modal("hide");
            $(".modal-backdrop").remove();

            clearAdForm();
            reloadTable();
        }).catch(() => {
            setStateReady(false);
            cogoToast.error("Something went wrong", option);
        });
    };

    const clearAdForm = () => {
        $('#image').val(null);
        $('#title').val(null);
        $('#description').val(null);
        set_cover_image(null);
        setValue(null)
        $('#add_license').parsley().destroy();
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const reloadTable = () => {
        $('#license').DataTable().ajax.reload();
    };

    return (
        <div className="content-body">
            <div className="container pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Licenses</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Licenses</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Licenses List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={reloadTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="license" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Type</th>
                                        <th className="wd-20p">Code</th>
                                        <th className="wd-20p">Mode</th>
                                        <th className="wd-20p">Size</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalAddLicense" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <div className="media align-items-center">
                                    <div className="media-body">
                                        <a href="" role="button" className="close pos-absolute t-15 r-15"
                                           data-dismiss="modal" aria-label="Close" onClick={resetForm.bind(null, "modalAddLicense", "add_license")}>
                                            <span aria-hidden="true">&times;</span>
                                        </a>
                                        <h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit License" : "Add License"}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="add_license" className="parsley-style-1" onSubmit={handleLicense.bind()}>
                                    <div id="typeWrapper" className="form-group parsley-input">
                                        <label>Type<span className="tx-danger">*</span></label>
                                        <select className="custom-select" id="type" required>
                                            <option value="" disabled selected>Select</option>
                                            <option value="Mining License">Mining License</option>
                                            <option value="Exploration License">Exploration License</option>
                                            <option value="Artisan License">Artisan License</option>
                                        </select>
                                    </div>

                                    <div id="commodityWrapper" className="form-group parsley-input">
                                        <label>Commodity<span className="tx-danger">*</span></label>
                                        <input id="commodity" type="text" className="form-control"
                                               placeholder="Enter commodity e.g Manganese"
                                               autoComplete="none"
                                               data-parsley-class-handler="#commodityWrapper"
                                               required
                                        />
                                    </div>

                                    <div id="sizeWrapper" className="form-group parsley-input">
                                        <label>Size<span className="tx-danger">*</span></label>
                                        <input id="size" type="number" className="form-control"
                                               placeholder="Enter size"
                                               autoComplete="none"
                                               data-parsley-class-handler="#sizeWrapper"
                                               required
                                        />
                                    </div>

                                    <div id="modeWrapper" className="form-group parsley-input">
                                        <label>Engagement Mode<span className="tx-danger">*</span></label>
                                        <input id="mode" type="text" className="form-control"
                                               placeholder="Enter mode e.g For Sale"
                                               autoComplete="none"
                                               data-parsley-class-handler="#modeWrapper"
                                               required
                                        />
                                    </div>

                                    <div id="provinceWrapper" className="form-group parsley-input">
                                        <label>Province<span className="tx-danger">*</span></label>
                                        <select className="custom-select" id="province" required>
                                            <option value="" disabled selected>Select</option>
                                            <option value="Lusaka">Lusaka</option>
                                            <option value="Copperbelt">Copperbelt</option>
                                            <option value="Central">Central</option>
                                            <option value="North-Western">North Western</option>
                                            <option value="Eastern">Eastern</option>
                                            <option value="Luapula">Luapula</option>
                                            <option value="Western">Western</option>
                                            <option value="Southern">Southern</option>
                                            <option value="Muchinga">Muchinga</option>
                                            <option value="Northern">Northern</option>
                                        </select>
                                    </div>

                                    <div id="whatsappWrapper" className="form-group parsley-input">
                                        <label>Whatsapp #<span className="tx-danger">*</span></label>
                                        <input id="whatsapp" type="number" className="form-control"
                                               placeholder="Enter mode e.g For Sale"
                                               autoComplete="none"
                                               data-parsley-class-handler="#whatsappWrapper"
                                               required
                                        />
                                    </div>

                                    <div id="emailWrapper" className="form-group parsley-input">
                                        <label>Email Address<span className="tx-danger">*</span></label>
                                        <input id="email" type="email" className="form-control"
                                               placeholder="Enter email address"
                                               autoComplete="none"
                                               data-parsley-class-handler="#emailWrapper"
                                               required
                                        />
                                    </div>

                                    <div id="mobileWrapper" className="form-group parsley-input">
                                        <label>Mobile #<span className="tx-danger">*</span></label>
                                        <input id="mobile_number" type="number" className="form-control"
                                               placeholder="Enter mobile number"
                                               autoComplete="none"
                                               data-parsley-class-handler="#mobileWrapper"
                                               required
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label>Instruction<span className="tx-danger">*</span></label>
                                        <ReactQuill
                                            id={"quill-id"}
                                            theme="snow"
                                            value={value}
                                            onChange={setValue}
                                            modules={modules}
                                            ref={quillRef}
                                            placeholder={'Write something...'}
                                        />
                                    </div>

                                    {stateReady ? <button disabled className="btn btn-primary btn-block"><span
                                        className="spinner-border spinner-border-sm mg-r-10" role="status"
                                        aria-hidden="true"/>Please Wait</button> : editForm ?
                                        <button className="btn btn-primary btn-block">Update License</button> :
                                        <button className="btn btn-primary btn-block">Create License</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default License;
