import React from "react";
import { Link } from "react-router-dom";

const Search = (props) => {

    return (
        <div className="content-header">
            <div className="content-search">
                <i data-feather="search"></i>
                <input type="search" id="query_value" className="form-control" placeholder="Search License"/>
            </div>
            <nav className="nav">
                <Link to="#" className="nav-link"><i data-feather="arrow-right-circle"></i></Link>
            </nav>
        </div>
    );
};

export default Search;
