import axios from 'axios';
import {
    ACTION_ADMIN_USER_URL,
    ADD_MEDIA_URL, DELETE_MEDIA_URL,
    DELETE_LICENSE_URL,
    DELETE_GALLERY_URL,
    DELETE_VIDEO_URL,
    EDIT_MEDIA_URL,
    GET_PRE_SIGNED_URL,
    UPDATE_LICENSE_URL,
    UPDATE_VIDEO_URL,
    GALLERY_LIST_URL,
    QUOTE_URL
} from "../api";
import errorHandler from "../utils/errorHandler";

export const updateLicense = (data) => {
    return axios.post(UPDATE_LICENSE_URL, data).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const deleteGallery = (data) => {
    return axios.post(DELETE_GALLERY_URL, data).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const getGallery = (pageSize) => {
    return axios.post(GALLERY_LIST_URL, {pageSize: pageSize}).then((response) => {
        return {
            data: response.data.gallery,
            total: response.data.total,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const getQuote = () => {
    return axios.get(QUOTE_URL).then((response) => {
        return {
            payload: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const deleteLicense = (data) => {
    return axios.post(DELETE_LICENSE_URL, data).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const updateVideo = (data) => {
    return axios.post(UPDATE_VIDEO_URL, data).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const deleteVideo = (data) => {
    return axios.post(DELETE_VIDEO_URL, data).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            error: true
        }
    });
}

export const getPreSignedUrl = () => {
    return axios.get(GET_PRE_SIGNED_URL).then((response) => {
        return {
            error: false,
            payload: response.data
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const uploadPhoto = (signed_url, binary, config) => {
    return axios.put(signed_url, binary, config).then((response) => {
        return {
            payload: response,
            error: false
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: error,
            error: true
        }
    });
}

export const addMedia = (data) => {
    return axios.post(ADD_MEDIA_URL, {data}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        return {
            data: error,
            error: true
        }
    });
};

export const editMedia = (data) => {
    return axios.post(EDIT_MEDIA_URL, {data}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        return {
            data: error,
            error: true
        }
    });
};

export const deleteMedia = (id) => {
    return axios.post(DELETE_MEDIA_URL, {id}).then((response) => {
        return {
            data: response.data,
            error: false
        }
    }).catch((err) => {
        return {
            data: err.response.data.error,
            error: true
        }
    });
};

export const addAdminUser = (data, action) => {
    return axios.post(ACTION_ADMIN_USER_URL, {data, action}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        return {
            data: error,
            error: true
        }
    });
};

export const editAdminUser = (data, action) => {
    return axios.post(ACTION_ADMIN_USER_URL, {data, action}).then((response) => {
        return {
            data: response.data.payload,
            error: false
        }
    }).catch((error) => {
        return {
            data: error,
            error: true
        }
    });
}

export const deleteAmin = (id, action) => {
    return axios.post(ACTION_ADMIN_USER_URL, {id, action}).then((response) => {
        return {
            data: response.data,
            error: false
        }
    }).catch((err) => {
        return {
            data: err.response.data.error,
            error: true
        }
    });
};
