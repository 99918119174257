// const API_URL = 'http://127.0.0.1:8089/v5';
const API_URL = 'https://server.geosetzambia.com/v5';

exports.SIGNIN_URL = `${API_URL}/sign-in`;
exports.CREATE_LICENSE_URL = `${API_URL}/create-license`;
exports.DELETE_LICENSE_URL = `${API_URL}/delete-license`;
exports.DELETE_GALLERY_URL = `${API_URL}/delete-gallery`;
exports.UPDATE_LICENSE_URL = `${API_URL}/update-license`;
exports.CREATE_VIDEO_URL = `${API_URL}/create-video`;
exports.DELETE_VIDEO_URL = `${API_URL}/delete-video`;
exports.UPDATE_VIDEO_URL = `${API_URL}/update-video`;
exports.GET_DATA_LIST = `${API_URL}/get-data-list`;
exports.GALLERY_LIST_URL = `${API_URL}/gallery-list`;
exports.QUOTE_URL = `${API_URL}/quote`;
exports.CREATE_QUOTE_URL = `${API_URL}/create-quote`;
exports.CREATE_GALLERY_URL = `${API_URL}/create-gallery`;
exports.FETCH_URL = `${API_URL}/image/`;
exports.GET_PRE_SIGNED_URL = `${API_URL}/generate-pre-signed-url`;
exports.DELETE_MEDIA_URL = `${API_URL}/delete-media`;
exports.ADD_MEDIA_URL = `${API_URL}/add-media`;
exports.EDIT_MEDIA_URL = `${API_URL}/edit-media`;
exports.ACTION_ADMIN_USER_URL = `${API_URL}/action-admin-user`;
